export const formatLink = string => {
  if (string === `undefined`) return;
  const stringNoSpecialCharacters = string && string?.replace(
    /[&/\\#,\.+()$~%'":*?!<>{}]/g,
    ''
  );
  return stringNoSpecialCharacters?.split(' ')?.join('-')?.toLowerCase();
};

export const getStructuredTextLabels = data => {
  return data.value.document.children
    .filter(child => child.type === 'heading')
    .map(heading => {
      return {
        label: heading.children.map(child => child.value).join(''),
        level: heading.level - 1 // Treat h2 as the top-level heading
      };
    });
};
