import { useEffect, useState } from 'react';
import { getStructuredTextLabels } from './tableOfContents';

const useBlogUtils = data => {
  const [tableOfContents, setTableOfContents] = useState([]);

  useEffect(() => {
    setTableOfContents(getStructuredTextLabels(data));
  }, [data]);
  return { tableOfContents };
};

export default useBlogUtils;
